import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import TextImageGrid from "../components/text-imagegrid";

import introImage from "../images/neu/smoothies-aufgestellt.jpg";
import repetLogo from "../images/icons/repet_logo.png";

import "./einfach-kostbar.scss";

const AboutPage = () => (
  <Layout className="einfach-kostbar">
    <SEO title="Einfach kostbar" />
    <Intro image={introImage} headline="Einfach <br>kostbar">
      <img src={repetLogo} alt="Wir sind rePET" />
    </Intro>
    <TextImageGrid
      headline="Warum werden Plastikbecher verwendet?"
      backgroundDark={true}
      smallColumn={true}
      images={[require("../images/neu/smoothie-rot.jpg")]}
    >
      <p>
        Unsere Plastikbecher und Flaschen sind keine herkömmlichen
        Plastikbecher- Flaschen, sondern werden aus recyceltem Material gemacht
        und sind zu 100% recycelbar. Selbstverständlich haben wir auch hier noch
        mehr in Petto. Du kannst zum Beispiel mit einer unserer praktischen
        Refill- Flaschen (oder deinem eigenen Gefäß) kommen und dir diese mit
        köstlichen Juices oder Smoothies auffüllen lassen. Das ist nicht nur
        umweltfreundlich, sondern auch günstiger, denn bei jedem Mal Auffüllen,
        geben wir dir einen kleinen Rabatt! Und wenn du es nicht eilig hast,
        kannst du deinen Smoothie oder Juice auch ganz gemütlich in einem Glas
        an der Bar genießen.
      </p>
    </TextImageGrid>
    <TextImageGrid
      headline="Gibt es keine besseren Alternativen zu den recycelbaren Plastikbechern-Flaschen?"
      smallColumn={true}
      reverse={true}
      images={[
        require("../images/neu/maedls.jpg"),
        require("../images/neu/smoothie-orange.jpg")
      ]}
    >
      <p>
        Wir haben uns sehr viele Gedanken über die Verpackung gemacht und uns
        selbst ist die Reduktion von Plastik natürlich auch ein großes Anliegen!
        Doch Alternativen wie beispielsweise biologisch abbaubare Becher sind
        auch nicht immer das Wahre. Diese abbaubaren Becher verrotten langsamer
        als normaler Biomüll, was zur Folge hat, dass frisch gewonnener Hummus
        durch diese Becher sauer wird. Eine weitere Problematik ist, dass es
        keine adäquaten Entsorgungsmöglichkeiten für biologisch abbaubares
        Verpackungsmaterial gibt. Diese müssten nämlich – wie es der Name schon
        vermuten lässt – in den Biomüll. Aus Angst vor Ratten gibt es aber weder
        auf der Straße, noch in Shopping Malls oder anderen öffentlichen Arealen
        Bio-Mülltonnen. Deshalb landen zum Beispiel biologisch abbaubare Becher
        erst recht wieder im Restmüll.
      </p>
      <p>
        Wir würden gerne ein Maximum an Nachhaltigkeit umsetzen, allerdings sind
        uns derzeit durch gesetzliche Regelungen teilweise die Hände gebunden.
        Wir finden aber, dass die Politik und Wirtschaft hier an einem Strang
        ziehen müssten, um eine gute Lösung in Form eines neuen
        umweltfreundlichen Materials als Plastikersatz zu finden. Leider
        passiert das im Moment nicht wirklich.
      </p>
    </TextImageGrid>
  </Layout>
);

export default AboutPage;
